<script>
import range from 'lodash/range';
import { getYear } from 'date-fns';
import allProvinces from 'provinces';
import filter from 'lodash/filter';
import { mapState } from 'vuex';
import {hasEditPermission , hasViewPermission,currentRoleLevelName} from '@/mixins/roleLevelPermissions';

export default {
	props: ['agreementData'],
	data() {
		const year = getYear(new Date());

		return {
			agreementYearOptions: range(year, year + 2),
			isViewMode : !hasEditPermission('section-basics')
		};
	},
	methods: {},
	computed:{
		...mapState(['user']),
		Provinces(){
			const provinces = filter(allProvinces, { country: 'CA' });
			let filteredProvince = provinces.filter((province)=>province.name !== "Quebec" && province.name !== this.user?.locationOfPractice);
			filteredProvince  = filteredProvince.map(obj => ({ ...obj, value: obj.name , text: obj.name }))
			if(this.user?.locationOfPractice){
				return [{name: this.user.locationOfPractice, value: this.user.locationOfPractice , text: this.user.locationOfPractice}, ...filteredProvince]
			}
			else{
				return filteredProvince
			}
		},
		propertyOptions(){
			let property=[
					'Family Residence',
					'Additional Real Property',
					'Business',
					'Corporation',
					'Pensions',
					'Canada Pension Plan',
					'RRSPs',
					'RESPs',
					'Financial Accounts',
					'Vehicles',
					'Other Property'
				]
			if(this.clientUserAgreement){
				property = property.filter(property => property !== 'Canada Pension Plan')
				return property
			}else{
				return property
			}
		},
		sectionOptions() {
			const hasParentingPermission = hasViewPermission('section-parenting');
			const hasChildSupportPermission = hasViewPermission('section-child-support');
			const hasSpousalSupportPermission = hasViewPermission('section-spousal-support');
			const hasPropertyPermission = hasViewPermission('section-property');
			let options = [
				'Parenting',
				'Child Support',
				'Spousal Support',
				'Property',
			];

			if (!hasParentingPermission) {
				options = options.filter(option => option !== 'Parenting');
			}

			if (!hasChildSupportPermission) {
				options = options.filter(option => option !== 'Child Support');
			}

			if (!hasSpousalSupportPermission) {
				options = options.filter(option => option !== 'Spousal Support');
			}

			if (!hasPropertyPermission) {
				options = options.filter(option => option !== 'Property');
			}
			return options;
		},
		clientUserAgreement(){
			return currentRoleLevelName == 'client-sharing-user' ? true : false;
		}
	},
	mounted(){
		if(!this.agreementData.agreement.updatedProvinceOfAgreement){
			this.$set(this.agreementData.agreement, 'updatedProvinceOfAgreement', this.agreementData.provinceOfAgreement);
			// this.agreementData.agreement.updatedProvinceOfAgreement = this.agreementData.provinceOfAgreement
		}
		if (!this.agreementData.agreement.updatedProvinceOfAgreement && this.user?.locationOfPractice) {
			this.agreementData.agreement.updatedProvinceOfAgreement = this.Provinces[0].name;
		}
	}
};
</script>

<template>
	<b-card>
		<h4 slot="header" class="section-heading">Matter Details</h4>

		<h4>Parties</h4>

		<party-name-input-group
			v-model="agreementData.partner1"
			label="Party 1"
			partner-id="partner1"
			class="mb-3"
			:testid="'matter-p1'"
			:disabled="isViewMode"
		/>

		<party-name-input-group
			v-model="agreementData.partner2"
			label="Party 2"
			partner-id="partner2"
			class="mb-3"
			:testid="'matter-p2'"
			:disabled="isViewMode"
		/>

		<h4>Agreement</h4>

		<!-- <b-form-group
				id="input-province-of-agreement"
				input-cols="8"
				label="Province of agreement"
				label-for="provinceOfAgreement"
			>
				<b-form-select
					id="provinceOfAgreement"
					v-model="agreementData.provinceOfAgreement"
					size="sm"
					:options="Provinces"
					text-field="name"
					value-field="name"
					data-testid='province-of-agreement'
					class="col-lg-8"
				/>
		</b-form-group> -->
		<p class="pt-2">Province of agreement</p>

		<b-row no-gutters>
			<b-col cols="4" class="col-12 col-sm-4">
				<b-form-select
					v-model="agreementData.agreement.updatedProvinceOfAgreement"
					:options="Provinces"
					:name="'Province of agreement'"
					data-testid="province-of-agreement"
					:disabled="isViewMode"
				>
				</b-form-select>
				<template #first>
					<b-form-select-option value="" disabled selected>
						Province
					</b-form-select-option>
				</template>
			</b-col>
		</b-row>

		<!-- <radio-group
			v-model="agreementData.agreement.provinceOfAgreement"
			class="mb-3 my-3"
			input-cols="4"
			select
			reset-undefined
			label="Province of agreement"
			name="Province of agreement"
			:options="Provinces"
			:testid="'province-of-agreement'"
		/> -->
		<!-- {{ Provinces }}
{{ agreementYearOptions }} -->
		<radio-group
			v-model="agreementData.agreement.year"
			class="mb-3 my-3"
			input-cols="4"
			select
			label="Year of Agreement"
			name="agreement-year"
			:options="agreementYearOptions"
			:testid="'matter-year-of-agreement'"
			:disabled="isViewMode"
		/>

		<check-group
			v-model="agreementData.agreement.sections"
			class="mb-3"
			label="What sections would you like to include in the Agreement?"
			:options="sectionOptions"
			:testid="'matter-agreement-sections'"
			:id="'matter-agreement-sections'"
			:disabled="isViewMode"
		/>

		<conditional-group
			class="mb-3"
			:condition="agreementData.agreement.sections.includes('Property') && sectionOptions.includes('Property')"
			@reset="agreementData.agreement.propertyRecitals = []"
		>
			<check-group
				v-model="agreementData.agreement.propertyRecitals"
				label="What property should be detailed in the Agreement?"
				:options="propertyOptions"
				:reset-undefined="false"
				:testid="'matter-agreement-property-options'"
				:id="'matter-agreement-property-options'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<slot />
	</b-card>
</template>
