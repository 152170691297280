<script>
import cloneDeep from 'lodash/cloneDeep';

import MatterDetails from '@/components/Agreements/MatterDetails';
import agreementTitle from '@/lib/agreement-title';
import base from '@/lib/agreement/base.json';
import kimAndKayne from '@/lib/agreement/kimAndKayne.json';
import featureFlag from '@/mixins/featureFlag';

import { analytics } from '../../firebase';

export default {
	components: {
		MatterDetails,
	},
	mixins: [featureFlag],
	data() {
		return {
			agreementData: cloneDeep(base),
			loading: false,
			error: '',
		};
	},
	computed: {
		showTest() {
			return (
				process.env.NODE_ENV === 'development' ||
				this.featureEnabled('feature_test_data')
			);
		},
	},
	methods: {
		agreementTitle,
		createAgreement() {
			this.loading = true;
			this.$store
				.dispatch('createAgreement', {
					...this.agreementData,
					title: agreementTitle(this.agreementData),
				})
				.then(({ id, error }) => {
					if (error) {
						this.error = 'Something unexpected went wrong.';
					} else if (id) {
						analytics.logEvent('agreement_created');
						this.$router.push({
							name: 'agreement',
							params: { id },
						});
					}
					this.loading = false;
				});
		},
		loadTestData() {
			this.agreementData = kimAndKayne;
			this.createAgreement();
		},
	},
};
</script>

<template>
	<b-container class="mt-5">
		<b-overlay
			spinner-variant="primary"
			:show="loading"
			rounded="sm"
			opacity="0.5"
			blur="2px"
			variant="white"
		>
			<matter-details :agreement-data="agreementData">
				<div class="actions">
					<b-button
						variant="primary"
						@click="createAgreement(agreementData)"
						>Create</b-button
					>
					<b-button v-if="showTest" @click="loadTestData()" class="ml-2">
						Test
					</b-button>
				</div>
				<div v-if="error">
					{{ error }}
				</div>
			</matter-details>
		</b-overlay>
	</b-container>
</template>
